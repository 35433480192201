import React, { useEffect, useState } from 'react';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';
import { useLocation } from '@reach/router';
import { useNavigationPopups } from '@contexts/MainAppContext';
import { useGetBook, useGetBookChapter } from '@app/helper/services';
import { getChapterContent } from '../../helper/helpers';

const ChapterPublic = () => {
  const location = useLocation();
  const { showSignupPopup } = useNavigationPopups();

  const data = useStaticQuery(
    graphql`
      query {
        book_cover_bg: file(relativePath: { eq: "bg-greenblack.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        chapter_bg: file(relativePath: { eq: "default-background.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const params = new URLSearchParams(location?.search);
  const bg_book_cover = getImage(data.book_cover_bg);
  const chapter_bg = getImage(data.chapter_bg);
  const bookHeroBg = convertToBgImage(bg_book_cover);
  const chapterHeroBg = convertToBgImage(chapter_bg);

  const chapterId = params.get('id');

  const { chapter, isLoading: isChapterLoading, error: chapterError } = useGetBookChapter(chapterId);
  const { book: currentBook, isLoading: isBookLoading, error: bookError } = useGetBook(chapter?.book?.id);

  const chapterContent = getChapterContent(chapter);

  let chapterBg = '';
  if (chapter?.bg_color) {
    chapterBg = chapterHeroBg;
  } else {
    chapterBg = bookHeroBg;
  }

  const chapterBackground = {
    background: `${chapter?.bg_color}`,
    position: 'absolute',
    left: '0',
    width: '100%',
    top: '0',
    bottom: '0',
    opacity: '0.9',
    zIndex: '-1',
  };

  useEffect(() => {
    if (chapterError || bookError) {
      console.error(chapterError);
      console.error(bookError);
      navigate('/404');
    }
  }, [chapterError, bookError]);

  if (isBookLoading || isChapterLoading) return <SpinnerDotPage />;
  if (chapterError || bookError) return <SpinnerDotPage />;
  if (!chapter || !currentBook) return <div className="text-xxs">No results</div>;

  return (
    <BackgroundImage Tag="section" className="bg-center" {...chapterBg}>
      <section className="w-full max-w xl:px-5 px-6 xl:pb-0 pb-10 pt-28 quill-editor">
        {chapter.bg_color ? <div style={chapterBackground} id="background-overlay"></div> : null}

        <div className="grid md:grid-cols-12 mt-8">
          <div className="w-full col-span-12 max-w-screen-lg mx-auto">
            <h3 className="font-rokkit font-bold xl:mb-7 mb-4 text-white uppercase text-center xl:text-6xl text-2xl">{currentBook.name}</h3>
            <h3 className="font-rokkit font-bold xl:mb-2 mb-0 text-white uppercase text-center xl:text-3xl text-lg">{chapter?.name}</h3>
            <h4 className="font-rokkit font-bold italic xl:mb-7 mb-0 text-white uppercase text-center xl:text-xl text-base">
              {chapter?.excerpt}
            </h4>
          </div>
        </div>

        <div className="grid md:grid-cols-12 transition-all opacity-1 duration-1000">
          <div className="w-full col-span-12 max-w-screen-lg mx-auto">
            <div className="chapter-contents xl:mt-12 mt-0 xl:px-[15px] px-0 ql-editor pb-20">
              <div className="text-white font-opensans leading-8 mb-10" dangerouslySetInnerHTML={{ __html: chapterContent }}></div>
            </div>

            <div className="lg:-ml-2.5 bg-read-more-bg w-full max-w bg-cover bg-left-top text-center mt-0 xl:pt-0 pt-5 xl:pb-10 mb-5">
              <StaticImage
                src={'../../images/book-icon.png'}
                formats={['auto', 'webp', 'avif']}
                width={90}
                alt="continue reading book"
                className="mx-auto"
                placeholder="blurred"
              />
              <h3 className="font-rokkit text-white xl:text-5xl text-3xl uppercase font-bold text-center mt-5">
                Wish to <span className="text-apache-800">continue</span> reading?
              </h3>
              <button
                onClick={() => showSignupPopup()}
                className="button-white font-bold font-opensans rounded-md mx-auto xl:text-xl text-lg xl:py-3 py-2 px-7 mt-5 transition-all duration-500">
                Sign up for free
              </button>
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  );
};

export default ChapterPublic;
