export const getChapterContent = (chapter, isFree = true) => {
  if (!chapter) return '';
  if (!isFree) return chapter?.content;

  let str = chapter.content;
  str = chapter.content.replace(/<\/p\s*>/g, '||');
  str = str.replace(/<\/?[^>]+(>|$)/g, '');
  str = str.substring(0, 1000) + ' ...';
  const arr = str.split('||');
  str = '<p ><span class="ql-size-large">' + arr.join('</span></p><br><p ><span class="ql-size-large">') + '</span></p>';

  return str;
};
