import React, { useEffect, useState } from 'react';
import MoreBooks from '@components/MoreBooks';
import { Link, navigate } from 'gatsby';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';
import { URL_ASSETS } from 'gatsby-env-variables';
import Slider from '@material-ui/core/Slider';
import { Popover } from '@material-ui/core';
import prev from '@images/prev.png';
import next from '@images/next.png';
import retangle from 'images/retangle.png';
import retangleFull from 'images/retangle-full.png';
import settingButton from 'images/setting-new.png';
import '@styles/chapter-new.css';
import { useLocation } from '@reach/router';
import { useAuth } from '@contexts/MainAppContext';
import { useGetBookChapter, useGetBook, useGetBooks } from '@app/helper/services';
import { getChapterContent } from '../../helper/helpers';

const ChapterPrivate = () => {
  const location = useLocation();
  const { isUserPlanFree } = useAuth();
  const params = new URLSearchParams(location?.search);
  const chapterId = params.get('id');
  const [chapterIndex, setChapterIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [font, setFont] = useState('telex');
  const [fontSize, setFontSize] = useState(21);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { chapter: currentChapter, isLoading: isChapterLoading, error: chapterError } = useGetBookChapter(chapterId);
  const { book: currentBook, isLoading: isBookLoading, error: bookError } = useGetBook(currentChapter?.book?.id);
  const { books, isLoading: isBooksLoading, error: booksError } = useGetBooks();

  const booksIdsWithFreeChapters = [1, 2, 3];
  const isFullContent =
    currentBook &&
    ((booksIdsWithFreeChapters.includes(parseInt(currentBook?.id)) && isUserPlanFree && chapterIndex <= 4) || !isUserPlanFree);

  const chapterContent = getChapterContent(currentChapter, !isFullContent);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const gotoChapterByIndex = (index) => {
    if (!(currentBook?.chapters && currentBook?.chapters.length > 0 && index >= 0 && index < currentBook?.chapters.length)) return;

    setChapterIndex(index);
    navigate(`/chapter?id=${currentBook?.chapters[index]?.id}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
        setOpenDropdown(false);
      };
    }
    return () => {
      window.onscroll = null;
      window.onclick = null;
    };
  }, []);

  useEffect(() => {
    console.log('currentBook', currentBook);
    if (currentBook && currentChapter) {
      for (let index = 0; index < currentBook.chapters.length; index++) {
        const chapter = currentBook.chapters[index];
        if (chapter.id == currentChapter.id) {
          setChapterIndex(index);
          break;
        }
      }
    }
  }, [currentBook, currentChapter]);

  useEffect(() => {
    window.scrollTo({ top: 370, behavior: 'smooth' });
    const element = document.getElementsByClassName('read-content');
    if (element && element.length && element.length > 0) {
      element[0].scrollTop = 0;
    }
  }, [chapterIndex]);

  const onChangeFont = (event) => {
    setFont(event.target.value);
  };

  useEffect(() => {
    if (chapterError || bookError || booksError) {
      console.error(chapterError);
      console.error(booksError);
      console.error(bookError);
      navigate('/404');
    }
  }, [chapterError, bookError, booksError]);

  if (isBookLoading || isBooksLoading || isChapterLoading) return <SpinnerDotPage />;
  if (bookError || chapterError || booksError) return <SpinnerDotPage />;
  if (!currentBook || !currentBook || !currentChapter || !books) return <div className="text-xxs">No results</div>;

  return (
    <div className="w-full bg-black ">
      <div
        className=""
        style={{
          backgroundImage: `linear-gradient(rgba(221, 188, 121, 0.2), rgb(0,0,0) 120%), url("${URL_ASSETS}${currentBook?.main_image_url}")`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          zIndex: 0,
          width: '100vw',
          height: '100vh',
          top: 0,
          left: 0,
          position: 'absolute',
          filter: `blur(5px)`,
        }}></div>
      <div
        className="w-full flex flex-col justify-center items-center pb-10 xl:pt-16 xl:pb-4 md:py-0 content-chapter"
        style={{
          textAlign: 'left',
          position: 'relative',
        }}>
        <div className={'mt-24 overflow-y-auto responsive-chapter'}>
          <p className="mt-[70px] top-text">
            <span style={{ color: '#FFFFFFB8' }}>Author: </span>Kiran Bhat
          </p>
          <h1
            className="chapter-title text-3xl lg:text-4xl font-rokkit xl:mb-7 mb-4 uppercase text-start "
            style={{ color: 'rgba(48, 45, 44, 0.5)' }}>
            {currentBook?.name}
          </h1>
          <div className="chapter-resume" dangerouslySetInnerHTML={{ __html: currentBook?.content }}></div>

          <div className="mt-4 mb-0" id="dropdown-chapters">
            <button
              onClick={() => setOpenDropdown(!openDropdown)}
              className="font-bold font-opensans rounded-md text-lg h-14 transition-all duration-500 dropdown-chapters">
              Chapters ({currentBook?.chapters.length})
              <div className="mr-4 inline-block float-right">
                <i className={`dropdown-chevron icon ${openDropdown ? 'dropdown-chevron-up' : ''}`}></i>
              </div>
            </button>
            {openDropdown && (
              <div className="content-chapters-dropdown">
                {currentBook?.chapters.map((item, index) => (
                  <div key={index}>
                    <button
                      className="flex flex-row text-white w-full  mb-4"
                      onClick={() => {
                        setOpenDropdown(false);
                        navigate(`/chapter?id=${item.id}`);
                      }}>
                      <div className="rounded-xl flex-none overflow-hidden">
                        <img
                          src={`${URL_ASSETS}${item.main_image_url}`}
                          width={60}
                          height={60}
                          alt={item.name}
                          className="block megamenu-chapter-img"
                        />
                      </div>
                      <div className="rounded-xl flex-grow ">
                        <p className="pl-4 text-left w-full">{`${index + 1}. ${item.name}`}</p>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isFullContent ? (
            <div className="mt-5 mb-0 text-xs text-white">
              For full content, please upgrade your plan.
              <br />
              <Link
                to="/pricing-guide"
                className="mt-2 inline-block button-white font-bold font-opensans rounded text-xs py-2 px-4 transition-all duration-300">
                Upgrade Your Plan
              </Link>
            </div>
          ) : null}
        </div>

        {currentChapter ? (
          <div className="responsive-chapter mt-20">
            <div className="py-10 bg-white rounded-t-xl lg:rounded-t-3xl overflow-y-auto overflow-x-hidden  read-content">
              <div
                className="w-6/6  px-2 xl:px-[50px] xl:py-[30px] lg:px-[30px] lg:py-[30px] "
                style={{ fontSize: `${fontSize}px`, fontFamily: `${font}`, lineHeight: '100%' }}>
                <h5 className="read-content-supertitle font-rokkits text-start">{`Chapter ${chapterIndex + 1}`}</h5>
                <h3 className="read-content-title  font-rokkit font-bold uppercase text-start ">{currentChapter.name}</h3>
                <h4 className="read-content-subtitle font-rokkits text-start">{currentChapter.excerpt}</h4>
                <div className="read-content-body" dangerouslySetInnerHTML={{ __html: chapterContent }}></div>
                {!isFullContent ? (
                  <div className="mt-10 mb-0 text-xs">
                    For full content, please upgrade your plan.
                    <br />
                    <Link
                      to="/pricing-guide"
                      className="mt-2 inline-block button-dark font-bold font-opensans rounded text-xs py-2 px-4 transition-all duration-300">
                      Upgrade Your Plan
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <div className="responsive-chapter">
          <div
            className="h-auto rounded-b-xl lg:rounded-b-3xl py-5 sm:mb-2 md:mb-4 lg:mb-6"
            style={{ backgroundColor: 'rgba(51, 51, 51, 1)' }}>
            <Popover
              id={chapterId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              {
                <div className="h-auto popover">
                  <div className="flex justify-end pt-2 pr-2">
                    <button onClick={handleClose} className="w-10 h-10 bg-white rounded-full flex justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#DDBB79">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div className="w-6/6 rounded-xl mx-auto py-5 px-10">
                    <div className="flex justify-between items-center w-full mx-auto mb-3 mt-3">
                      <span className="font-opensans text-xl">Font</span>

                      <select
                        className="w-4/6 h-12 bg-white text-black font-opensans text-lg border-2 border-gray-200 px-2"
                        onChange={onChangeFont}>
                        <option value="telex" style={{ fontSize: '20px' }}>
                          Telex
                        </option>
                        <option value="rokkitt" style={{ fontSize: '20px' }}>
                          Rokkitt
                        </option>
                        <option value="Book Antiqua" style={{ fontSize: '20px' }}>
                          Book Antiqua
                        </option>
                        <option value="Courier" style={{ fontSize: '20px' }}>
                          Courier
                        </option>
                        <option value="Georgia" style={{ fontSize: '20px' }}>
                          Georgia
                        </option>
                        <option value="Ms serif" style={{ fontSize: '20px' }}>
                          MS Serif
                        </option>
                        <option value="Arial" style={{ fontSize: '20px' }}>
                          Arial
                        </option>
                        <option value="Calibri" style={{ fontSize: '20px' }}>
                          Calibri
                        </option>
                        <option value="Tahoma" style={{ fontSize: '20px' }}>
                          Tahoma
                        </option>
                        <option value="Helvética" style={{ fontSize: '20px' }}>
                          Helvética
                        </option>
                        <option value="Verdana" style={{ fontSize: '20px' }}>
                          Verdana
                        </option>
                      </select>
                    </div>

                    <div className="flex justify-between items-center w-full mx-auto mb-5">
                      <span className="font-opensans text-xl">Font size</span>

                      <div className="w-4/6 h-12 flex items-center">
                        <Slider
                          size="small"
                          aria-label="Small"
                          valueLabelDisplay="auto"
                          min={16}
                          max={40}
                          value={fontSize}
                          onChange={(e, value) => setFontSize(value)}
                          style={{ color: '#DDBB79' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Popover>

            <div className="flex flex-row px-4 xl:px-10 progress-lines justify-between items-center">
              <div className=" flex flex-none justify-start items-center">
                <span className="text-white whitespace-nowrap " style={{ fontSize: '14px' }}>{`Chap ${chapterIndex + 1} / ${
                  currentBook?.chapters?.length
                }`}</span>
              </div>

              <div className="flex flex-row flex-wrap flex-grow px-4 items-center">
                {currentBook?.chapters?.map((element, index) => (
                  <div className="w-[20px] mx-1 my-1" key={index}>
                    <img className="w-[20px] h-[5px]" src={index == chapterIndex ? retangleFull : retangle} alt="retangle" width={100} />
                  </div>
                ))}
              </div>

              <div className="flex items-center flex-none">
                <div className="">
                  {chapterIndex > 0 && (
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        gotoChapterByIndex(chapterIndex - 1);
                      }}>
                      <img src={prev} alt="prev" height={32} width={32} />
                    </a>
                  )}
                </div>
                <div className="mx-2">
                  {chapterIndex < currentBook?.chapters?.length - 1 && (
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        gotoChapterByIndex(chapterIndex + 1);
                      }}>
                      <img src={next} alt="next" height={32} width={32} />
                    </a>
                  )}
                </div>

                <div className="">
                  <a
                    aria-describedby={chapterId}
                    variant="contained"
                    onClick={handleClick}
                    className="rounded-full bg-transparent font-opensans"
                    style={{ fontSize: '33px', color: '#DDBB79' }}>
                    <img src={settingButton} alt="settings" height={32} width={32} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsive-chapter mt-20">
          <h6 className="text-5xl md:text-6xl lg:text-4xl  font-rokkit font-bold  text-start text-white mb-4">More Books</h6>
          <div className="grid grid-cols-12 gap-0 -mx-5 content-more-books">
            <MoreBooks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterPrivate;
