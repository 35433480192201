import React from 'react';
import MoreBookColumn from '../components/containers/MoreBookColumn';
import SpinnerDot from './Spinners/SpinnerDot';
import { useGetAllBooks } from '@app/helper/services';

const RelatedBooks = () => {
  const { books, isLoading: isBooksLoading, error: booksError } = useGetAllBooks(8);

  if (isBooksLoading) return <SpinnerDot className="flex h-full w-full bg-transparent" />;
  if (!books || booksError) return <div className="text-xxs">No results</div>;

  return (
    <>
      {books.map((book) => (
        <MoreBookColumn book={book} key={book.id} />
      ))}
    </>
  );
};

export default RelatedBooks;
