import React from 'react';
import { useAuth } from '@contexts/MainAppContext';
import Layout from '@components/layouts/Layout';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import ChapterPrivate from '@components/chapters/ChapterPrivate';
import ChapterPublic from '@components/chapters/ChapterPublic';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';

const ChapterPage = () => {
  const { isUserLoggedIn, isUserInformationLoading } = useAuth();

  console.log('ChapterPage isUserLoggedIn', isUserLoggedIn);

  if (isUserInformationLoading) return <SpinnerDotPage />;

  if (!isUserLoggedIn)
    return (
      <Layout pageTitle="Chapter">
        <ChapterPublic />
      </Layout>
    );

  return (
    <LayoutPrivate pageTitle="Chapter">
      <ChapterPrivate />
    </LayoutPrivate>
  );
};

export default ChapterPage;
