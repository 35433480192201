import React from "react";
import { SpinnerDotted } from 'spinners-react';

const SpinnerDot = (props) => {
  return (
    <div className={props.className}>
        <div className="m-auto">
          <SpinnerDotted size={90} thickness={180} speed={100} color="rgba(153, 127, 75, 1)" />
        </div>
    </div>
  );
};

export default SpinnerDot;